<template>
  <v-tooltip bottom :max-width="width" :value="showHelpTooltip">
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        width="18px"
        height="18px"
        style="cursor: pointer"
        class="mr-4"
        color="#6739b6"
        @click="company.firstLogin = true"
      >
        <v-img width="5px" :src="require('@/assets/img/icons/carbon_information.svg')" />
      </v-btn>
    </template>

    <span style="text-align: justify">
      <p style="margin-bottom: 0; padding-bottom: 0">{{ description }}</p>
      <p style="margin-bottom: 0; padding-bottom: 0">{{ description2 }}</p>
      <p style="margin-bottom: 0; padding-bottom: 0">{{ description3 }}</p>
      <p style="margin-bottom: 0; padding-bottom: 0">{{ description4 }}</p>
      <p style="margin-bottom: 0; padding-bottom: 0" v-if="formula != ''">
        {{ formula }}
      </p>
      <p style="margin-bottom: 0; padding-bottom: 0" v-if="objective != ''">
        {{ objective }}
      </p></span
    >
  </v-tooltip>
</template>

<script>
export default {
  name: "ToolTips",
  props: {
    description: {
      type: String,
      default: null,
    },
    description2: {
      type: String,
      default: null,
    },
    description3: {
      type: String,
      default: null,
    },
    description4: {
      type: String,
      default: null,
    },
    formula: {
      type: String,
      default: "",
    },
    objective: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "210px",
    },
  },

  data: function () {
    return {};
  },
};
</script>
