import uaaAxios from "@/utils/authAxios";

const apiURL = process.env.VUE_APP_PMO_API;

export default {
  getFlowEfficiency: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/agility/chartFlowEfficiency`, project)
        .then((response) => {
          resolve(response.data);
          //console.log(response.data);
          // resolve(flowEfficiency);
        })
        .catch((err) => reject(err));
    });
  },
  getFlowRate: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/agility/chartFlowRate`, project)
        .then((response) => {
          resolve(response.data);
          //console.log(response.data);
          //resolve(flowRate);
        })
        .catch((err) => reject(err));
    });
  },
  getFlowPredictability: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/agility/chartFlowPredictability`, project)
        .then((response) => {
          resolve(response.data);
          console.log(response.data);
          //resolve(flowPredictability);
        })
        .catch((err) => reject(err));
    });
  },
  getFlowTime: function (project) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/agility/chartFlowTime`, project)
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
          //resolve(flowTime);
        })
        .catch((err) => reject(err));
    });
  },
  getAgilities: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/agility/allAgility`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  saveAgility(request) {
    const json = JSON.stringify(request);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/agility/saveUpdateAgility`, json)
        .then((response) => {
          resolve(response);
          console.log(response.data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteAgility: function (idAgility) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .delete(`${apiURL}/agility/deleteAgility?idAgility=${idAgility}`)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  getAgilityDash: function (project) {
    const json = JSON.stringify(project);
    return new Promise((resolve, reject) => {
      uaaAxios
        .post(`${apiURL}/agility/getChartAgilityDashboard`, json)
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
};
